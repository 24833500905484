import React, {Component, createRef, lazy, Suspense} from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Route } from 'react-router-dom';
import RouteEnum from '../constants/RouteEnum';
import LoadingIndicator from './components/loading-indicator/LoadingIndicator';
import './App.scss';
import './fonts.scss';
import Startup from './components/startup';
import Nav from './components/nav';
import Footer from './components/footer';
import { CSSTransition } from 'react-transition-group';
import PageWrapper from './components/page-wrapper';
import { initGA } from './components/tracking';
const Home = lazy(() => import('./pages/home'));
const NotFoundPage = lazy(() => import('./pages/not-found-page/NotFoundPage'));
const Blogs = lazy(() => import('./pages/blogs'));
const Blog = lazy(() => import('./pages/blog'));
const WhatWeDo = lazy(() => import('./pages/what-we-do'));
const AboutUs = lazy(() => import('./pages/about-us'));
const Contact = lazy(() => import('./pages/contact'));
const PrivacyPolicy = lazy(() => import('./pages/privacy-policy'));
const Gdpr = lazy(() => import('./components/gdpr'));
const Capabilities = lazy(() => import('./pages/capabilities'));
const Trends = lazy(() => import('./pages/trends'));
const VideoGallery = lazy(() => import('./pages/video-gallery'));
const ImageGallery = lazy(() => import('./pages/image-gallery'));
const Sparkshoppe = lazy(() => import('./pages/sparkshoppe'));
const VimaxXDole = lazy(() => import('./pages/vimax-x-dole'));
const VimaxXGE = lazy(() => import('./pages/vimax-x-ge'));
const VimaxXBB = lazy(() => import('./pages/vimax-x-bb'));
const VimaxXCoke = lazy(() => import('./pages/vimax-x-coke'));
const VimaxXCokeTwo = lazy(() => import('./pages/vimax-x-coke/CokeViewTwo'));
const FeaturesAndBenefits = lazy(() => import('./pages/features-and-benefits'));
const Pricing = lazy(() => import('./pages/pricing'));
const Collection = lazy(() => import('./pages/collection'));
const ZoomInfoLP = lazy(() => import('./pages/zoom-info-landing-page'));
const VimaxXFiorucci = lazy(() => import('./pages/vimax-x-fiorucci'));
const VimaxXNorthfork = lazy(() => import('./pages/vimax-x-northfork'));
const Testimonials = lazy(() => import('./pages/testimonials'));
const CollectionMarketing = lazy(() => import('./pages/collection-marketing'));
const VideoGalleryLipariView = lazy(() => import('./pages/video-gallery-lipari'));
const VideoGalleryHoliday = lazy(() => import('./pages/video-gallery-holiday'));
const VideoGalleryQ1 = lazy(() => import('./pages/video-gallery-q1'));

VideoGalleryQ1
class App extends React.Component {
    componentDidMount() {
        initGA('GTM-N9FPP7K');
    }

    render() {
        const onEnter = (node) => {
            // enter animation
            // if (!node.children[0]) return;
            //
            // const targets = gsap.utils.toArray(node.children[0].children);
            //
            // gsap.from(targets, {
            //     delay: 0.3,
            //     y: 30,
            //     opacity: 0,
            //     stagger: 0.6,
            // });
        };

        const onExit = (node) => {
            // exit animation
            // if (!node) return;
        };

        const routes = [
            { path: RouteEnum.Home, name: 'Home', Component: Home, transparentNav: true },
            { path: RouteEnum.AboutUs, name: 'About Us', Component: AboutUs, transparentNav: false },
            { path: RouteEnum.Blogs, name: 'Blogs', Component: Blogs, transparentNav: false },
            { path: RouteEnum.Blog, name: 'Blog', Component: Blog, transparentNav: false },
            { path: RouteEnum.WhatWeDo, name: 'What We Do', Component: WhatWeDo, transparentNav: false },
            { path: RouteEnum.Contact, name: 'Contact', Component: Contact, transparentNav: false },
            { path: RouteEnum.PrivacyPolicy, name: 'Privacy Policy', Component: PrivacyPolicy, transparentNav: false },
            { path: RouteEnum.Capabilities, name: 'Capabilities', Component: Capabilities, transparentNav: false },
            { path: RouteEnum.Trends, name: 'Trends', Component: Trends, transparentNav: false },
            { path: RouteEnum.VideoGallery, name: 'Video Gallery', Component: VideoGallery, transparentNav: false },
            { path: RouteEnum.ImageGallery, name: 'Image Gallery', Component: ImageGallery, transparentNav: false },
            { path: RouteEnum.Sparkshoppe, name: 'Sparkshoppe', Component: Sparkshoppe, transparentNav: false },
            { path: RouteEnum.VimaxXDole, name: 'Vimax x Dole', Component: VimaxXDole, transparentNav: false },
            { path: RouteEnum.VimaxXGE, name: 'Vimax x Giant Eagle & Coke', Component: VimaxXGE, transparentNav: false },
            { path: RouteEnum.VimaxXBB, name: 'Vimax x Brookshire Brothers', Component: VimaxXBB, transparentNav: false },
            { path: RouteEnum.FeaturesAndBenefits, name: 'Features & Benefits', Component: FeaturesAndBenefits, transparentNav: false },
            { path: RouteEnum.Pricing, name: 'Pricing', Component: Pricing, transparentNav: false },
            { path: RouteEnum.Collection, name: 'Collection', Component: Collection, transparentNav: false },
            { path: RouteEnum.VimaxXCoke, name: 'Vimax x Coke', Component: VimaxXCoke, transparentNav: false },
            { path: RouteEnum.VimaxXCokeTwo, name: 'Vimax x Coke Two', Component: VimaxXCokeTwo, transparentNav: false},
            { path: RouteEnum.ZoomInfoLP, name: 'Vimax Media', Component: ZoomInfoLP, transparentNav: false},
            { path: RouteEnum.VimaxXFiorucci, name: 'Vimax x Fiorucci', Component: VimaxXFiorucci, transparentNav: false },
            { path: RouteEnum.VimaxXNorthfork, name: 'Vimax x Northfork', Component: VimaxXNorthfork, transparentNav: false },
            { path: RouteEnum.Testimonials, name: 'Vimax Testimonials', Component: Testimonials, transparentNav: false },
            { path: RouteEnum.CollectionMarketing, name: 'Vimax Collections', Component: CollectionMarketing, transparentNav: false },
            { path: RouteEnum.VideoGalleryLipari, name: 'Vimax x Lipari', Component: VideoGalleryLipariView, transparentNav: false },
            { path: RouteEnum.VideoGalleryHoliday, name: 'Vimax Holiday Videos', Component: VideoGalleryHoliday, transparentNav: false },
            { path: RouteEnum.VideoGalleryQ1, name: 'Vimax Video Gallery', Component: VideoGalleryQ1, transparentNav: false}
        ];

        return (
            <Startup>
                <ConnectedRouter history={this.props.history}>
                    <Suspense fallback={<LoadingIndicator isActive={true} />}>
                        <Gdpr />
                        <div className={'w-full container'}>
                            {routes.map(({ path, name, Component, transparentNav }) => {
                                return (
                                    <Route key={name} path={path} exact={true}>
                                        {({ match }) => {
                                            return (
                                                <CSSTransition
                                                    in={match != null}
                                                    timeout={0}
                                                    classNames={'page'}
                                                    unmountOnExit
                                                    // onExit={onExit}
                                                    onEntered={onEnter}
                                                >
                                                    <PageWrapper location={this.props.history.location}>
                                                        <Nav transparent={transparentNav} />
                                                        <Component />
                                                    </PageWrapper>
                                                </CSSTransition>
                                            );
                                        }}
                                    </Route>
                                );
                            })}
                            {/*<Route component={NotFoundPage} />*/}
                            <Footer />
                        </div>
                    </Suspense>
                </ConnectedRouter>
            </Startup>
        );
    }
}

App.propTypes = {
    history: PropTypes.object.isRequired,
};

export default App;
