import environment from 'environment';
import EffectUtility from '../../utilities/EffectUtility';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import ImageModel from './models/image/ImageModel';

export default class ImageGalleryEffect {
    public static async requestImages(): Promise<ImageModel | HttpErrorResponseModel> {
        const endpoint: string = environment.api.strapi.replace(':endpoint', 'images');

        return EffectUtility.getToModel(ImageModel, endpoint);
    }
}
